import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
//
import Iconify from '../iconify';
import Image from '../image';
//
import { UploadProps } from './types';
import RejectionFiles from './errors-rejection-files';
import SingleFilePreview from './preview-single-file';

// ----------------------------------------------------------------------

export default function UploadDocument({ error, file, disabled, labelText, helperText, sx, ...other }: UploadProps) {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        multiple: false,
        disabled,
        accept: {
            'image/*': []
        },
        ...other
    });

    const hasFile = !!file;

    const hasError = isDragReject || !!error;

    const imgUrl = typeof file === 'string' ? file : file?.preview;

    const renderPreview = hasFile && (
        <Image
            alt="avatar"
            src={imgUrl}
            sx={{
                width: '100%',
                height: '100px',
                borderRadius: '5px'
            }}
        />
    );

    const renderSinglePreview = <SingleFilePreview imgUrl={typeof file === 'string' ? file : file?.preview} />;

    const renderPlaceholder = (
        <Stack
            alignItems="center"
            justifyContent="center"
            spacing={1}
            className="upload-placeholder"
            sx={{
                top: 0,
                left: 0,
                width: '100%',
                height: '100px',
                zIndex: 9,
                borderRadius: '5px',
                position: 'absolute',
                color: 'text.disabled',
                bgcolor: '#13212D',
                // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
                // transition: (theme) =>
                //     theme.transitions.create(['opacity'], {
                //         duration: theme.transitions.duration.shorter
                //     }),
                // '&:hover': {
                //     opacity: 0.72
                // },
                // ...(hasError && {
                //     color: 'error.main',
                //     bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
                // }),
                // ...(hasFile && {
                //     zIndex: 9,
                //     opacity: 0,
                //     color: 'common.white',
                //     bgcolor: (theme) => alpha(theme.palette.grey[900], 0.64)
                // })
            }}
        >
            <Iconify icon="gg:arrow-up-o" width={40} />
            <Typography
                variant="caption"
                sx={{

                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    bgcolor: '#068DEE',
                    color: '#1A2C38',
                    borderRadius: '5px',
                    padding: '5px 10px',
                    width: 'max-content',
                    fontWeight: 700
                }}
            >
                {labelText}

            </Typography>
            {/* <Typography variant="caption">{file ? 'Update photo' : 'Upload photo'}</Typography> */}
        </Stack>
    );

    const renderContent = (
        <Box
            sx={{
                width: '100%',
                height: '100px',
                overflow: 'hidden',
                borderRadius: '5px',
                position: 'relative'
            }}
        >
            {renderPreview}
            {renderPlaceholder}
        </Box>
    );

    return (
        <>
            <Box
                {...getRootProps()}
                sx={{
                    p: 1,
                    // m: 'auto',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    bgcolor: '#13212D',
                    // borderRadius: '5px',
                    // border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
                    // ...(isDragActive && {
                    //     opacity: 0.72
                    // }),
                    // ...(disabled && {
                    //     opacity: 0.48,
                    //     pointerEvents: 'none'
                    // }),
                    // ...(hasError && {
                    //     borderColor: 'error.main'
                    // }),
                    // ...(hasFile && {
                    //     ...(hasError && {
                    //         bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
                    //     }),
                    //     '&:hover .upload-placeholder': {
                    //         opacity: 1
                    //     }
                    // }),
                    ...sx
                }}
            >
                <input {...getInputProps()} />
                {/* {hasFile ? renderSinglePreview : renderPlaceholder} */}

                {renderContent}
            </Box>

            {helperText && helperText}

            <RejectionFiles fileRejections={fileRejections} />
        </>
    );
}
