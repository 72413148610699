import { useFormContext, Controller } from 'react-hook-form';
// @mui
import FormHelperText from '@mui/material/FormHelperText';
//
import { UploadAvatar, Upload, UploadBox, UploadProps, UploadDocument } from '../upload';

// ----------------------------------------------------------------------

interface Props extends Omit<UploadProps, 'file'> {
    name: string;
    multiple?: boolean;
}

// ----------------------------------------------------------------------

export function RHFUploadAvatar({ name, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div>
                    <UploadAvatar error={!!error} file={field.value} {...other} />

                    {!!error && (
                        <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                            {error.message}
                        </FormHelperText>
                    )}
                </div>
            )}
        />
    );
}

// ----------------------------------------------------------------------

export function RHFUploadBox({ name, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => <UploadBox files={field.value} error={!!error} {...other} />}
        />
    );
}

// ----------------------------------------------------------------------

export function RHFUpload({ name, multiple, helperText, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) =>
                multiple ? (
                    <Upload
                        multiple
                        accept={{ 'image/*': [] }}
                        files={field.value}
                        error={!!error}
                        helperText={
                            (!!error || helperText) && (
                                <FormHelperText error={!!error} sx={{ px: 2 }}>
                                    {error ? error?.message : helperText}
                                </FormHelperText>
                            )
                        }
                        {...other}
                    />
                ) : (
                    <Upload
                        accept={{ 'image/*': [] }}
                        file={field.value}
                        error={!!error}
                        helperText={
                            (!!error || helperText) && (
                                <FormHelperText error={!!error} sx={{ px: 2 }}>
                                    {error ? error?.message : helperText}
                                </FormHelperText>
                            )
                        }
                        {...other}
                    />
                )
            }
        />
    );
}


// ----------------------------------------------------------------------

export function RHFUploadDocument({ name, helperText, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div>
                    <UploadDocument error={!!error} file={field.value} {...other} />
                    {helperText && (
                        <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                            {helperText}
                        </FormHelperText>
                    )}

                    {!!error && (
                        <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                            {error.message}
                        </FormHelperText>
                    )}
                </div>
            )}
        />
    );
}
