export const monthsList = [
  { code: '01', label: 'January', phone: '1-264' },
  { code: '02', label: 'February', phone: '355' },
  { code: '03', label: 'March', phone: '374' },
  { code: '04', label: 'April', phone: '244' },
  { code: '05', label: 'May', phone: '672' },
  { code: '06', label: 'June', phone: '54' },
  { code: '07', label: 'July', phone: '1-684' },
  { code: '08', label: 'August', phone: '43' },
  { code: '09', label: 'September', phone: '43' },
  { code: '10', label: 'October', phone: '43' },
  { code: '11', label: 'November', phone: '43' },
  { code: '12', label: 'December', phone: '43' },
];
