import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useState, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { decode } from 'string-encode-decode';
import Logo from 'src/components/logo';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

// apis
import { twoStopVerify } from 'src/api/user';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// assets
import { countries, monthsList } from 'src/assets/data';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField, RHFAutocomplete, RHFUploadDocument } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function JwtRegisterView() {
    const { register } = useAuthContext();

    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const password = useBoolean();
    const confirmPassword = useBoolean();

    const [verifycode, setVerifyCode] = useState('');
    const [verifyemail, setVerifyEmail] = useState('');

    const [state, setState] = useState(false);
    const [verifystate, setVerifyState] = useState(false);
    const [uploadDocumentState, setUploadDocumentState] = useState(false);

    const [registerData, setRegisterData] = useState<any>({});

    // const [frontFile, setFrontFile] = useState<any>({});
    // const [backFile, setBackFile] = useState<any>({});
    const [frontFile, setFrontFile] = useState<File | null>(null);
    const [backFile, setBackFile] = useState<File | null>(null);
    const [documenttype, setDocumentType] = useState('');

    // const [documentupload, setDocumentupload] = useState();

    const RegisterSchema = Yup.object().shape({
        firstname: Yup.string().required('Name required'),
        lastname: Yup.string().required('Surname required'),
        country: Yup.string().required('Choose a country'),
        // phonenumber: Yup.string().required('Phonenumber required'),
        phonenumber: Yup.string().required('Phonenumber required').matches(/^\d+$/, 'Phonenumber must be a number'), // Updated to string
        username: Yup.string().required('Username a required'),
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
        password: Yup.string().required('Password is required'),
        confirmNewPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
        month: Yup.string().required('Month is required'),
        // day: Yup.string().min(1, 'Day must be at least 1').max(31, 'Day must be at most 31').required('Day is required').matches(/^\d+$/, 'Day must be a number'),
        // year: Yup.string().min(1900, 'Year must be at least 1900').max(new Date().getFullYear(), `Year cannot be in the future`).required('Year is required').matches(/^\d+$/, 'Year must be a number'),
        day: Yup.string()
            .required('Day is required')
            .matches(/^\d+$/, 'Day must be a number')
            .test('day-range', 'Day must be between 1 and 31', (value) => {
                const day = Number(value);
                return day >= 1 && day <= 31;
            }),

        year: Yup.string()
            .required('Year is required')
            .matches(/^\d+$/, 'Year must be a number')
            .test('year-range', 'Year cannot be in the future or before 1900', (value) => {
                const year = Number(value);
                return year >= 1900 && year <= new Date().getFullYear();
            }),
        // day: Yup.string().required('Day is required').matches(/^\d+$/, 'Day must be a number'),
        // year: Yup.string().required('Year is required').matches(/^\d+$/, 'Year must be a number'),
        // documenttype: Yup.string().required('Please Enter Your Document Type'),
        // documents: Yup.mixed().required('Front document is required'), // Make this required
        // backdocuments: Yup.mixed().required('Back document is required'), // Make this required
    });

    const defaultValues = {
        firstname: '',
        lastname: '',
        country: '',
        phonenumber: '',
        username: '',
        email: '',
        password: '',
        confirmNewPassword: '',
        month: '',
        day: '',
        year: '',
        // documenttype: '',
        // documents: '',
        // backdocuments: ''
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues
    });

    const {
        // setValue,
        handleSubmit,
        formState: { isSubmitting, errors },
        reset
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        try {
            setErrorMsg('');
            setRegisterData({
                email: data.email,
                password: data.password,
                firstname: data.firstname,
                lastname: data.lastname,
                username: data.username,
                phonenumber: data.phonenumber,
                country: data.country,
                month: data.month,
                day: data.day,
                year: data.year,
                // documenttype: data.documenttype,
                // documents: data.documents,
                // backdocuments: data.backdocuments
            });
            setVerifyEmail(data.email);
            // const formData = new FormData();
            // formData.append('email', data.email);
            // formData.append('password', data.password);
            // formData.append('firstname', data.firstname);
            // formData.append('lastname', data.lastname);
            // formData.append('username', data.username);
            // formData.append('phonenumber', data.phonenumber);
            // formData.append('country', data.country);
            // formData.append('currency', 'USD');
            // formData.append('status', 'pending');
            // await register(formData);

            // await register?.(
            //     data.email,
            //     data.password,
            //     data.firstname,
            //     data.lastname,
            //     data.username,
            //     data.country,
            //     data.currency
            // );
            // setErrorMsg('Email sevice error. Try Again');
            const code = await twoStopVerify(data.email);
            if (code.success === 'error') {
                setErrorMsg('Email sevice error. Try Again');
            } else {
                setState(true);
                window.localStorage.setItem('success', code.success);
            }
            // router.push(returnTo || PATH_AFTER_LOGIN);
        } catch (error) {
            console.error(error);
            // reset();
            setErrorMsg(typeof error === 'string' ? error : error.message);
        }
    });

    const handleDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            Object.assign(file, {
                preview: URL.createObjectURL(file)
            });

            if (file) {
                setFrontFile(file)
                setErrorMsg(''); // Clear error message on successful file selection
                // setValue('documents', file, { shouldValidate: true });
            }
        },
        [setFrontFile]
    );

    const handleBackDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            Object.assign(file, {
                preview: URL.createObjectURL(file)
            });

            if (file) {
                setBackFile(file)
                setErrorMsg(''); // Clear error message on successful file selection
                // setValue('documents', file, { shouldValidate: true });
            }
        },
        [setBackFile]
    );

    const handleVerify = async () => {
        console.log(registerData);
        try {

            const confirmCode = window.localStorage.getItem('success');
            if (verifycode === decode(confirmCode)) { // Updated to use `verifycode`
                setUploadDocumentState(true);
                // const formData = new FormData();
                // formData.append('email', registerData.email);
                // formData.append('password', registerData.password);
                // formData.append('firstname', registerData.firstname);
                // formData.append('lastname', registerData.lastname);
                // formData.append('username', registerData.username);
                // formData.append('phonenumber', registerData.phonenumber);
                // formData.append('country', registerData.country);
                // formData.append('documenttype', registerData.documenttype);
                // formData.append('currency', 'USD');
                // formData.append('status', 'pending');
                // if (registerData.documents) { // Ensure documents are being referenced correctly
                //     formData.append('documents', registerData.documents); // Append the file
                // }

                // // Call the register function (assuming it takes FormData)
                // await register(formData);
                // setVerifyState(false); // Reset the verification state
            } else {
                setErrorMsg('Verify Code is Wrong');
            }
        } catch (error) {
            setErrorMsg(typeof error === 'string' ? error : error.message);
        }
    };

    const handleDocumentUpload = async () => {
        console.log(registerData);
        try {
            // setState(false);
            // setUploadDocumentState(false);
            const confirmCode = window.localStorage.getItem('success');

            // Check if document type is selected
            if (documenttype === '') {
                setErrorMsg('Document Type is missing');
                return; // Exit if document type is not provided
            }

            // Check if both documents are uploaded
            if (!frontFile) {
                setErrorMsg('Front document is required');
                return; // Exit if front document is missing
            }

            if (!backFile) {
                setErrorMsg('Back document is required');
                return; // Exit if back document is missing
            }

            // If both documents are uploaded, proceed with the upload
            const formData = new FormData();
            formData.append('email', registerData.email);
            formData.append('password', registerData.password);
            formData.append('firstname', registerData.firstname);
            formData.append('lastname', registerData.lastname);
            formData.append('username', registerData.username);
            formData.append('phonenumber', registerData.phonenumber);
            formData.append('country', registerData.country);
            formData.append('month', registerData.month);
            formData.append('day', registerData.day);
            formData.append('year', registerData.year);
            formData.append('currency', 'USD');
            formData.append('status', 'pending');
            formData.append('roleId', 'user');
            formData.append('documents', frontFile); // Append the front file
            formData.append('backdocuments', backFile); // Append the back file
            formData.append('documenttype', documenttype);

            // Call the register function (assuming it takes FormData)
            await register(formData);
            setSuccessMsg('');
            setErrorMsg('');
            setState(false); // Reset the verification state
        } catch (error) {
            setState(false);
            setUploadDocumentState(false);

            if (typeof error === 'string' && error.includes('Registraion is Done')) {
                setErrorMsg('');
                setSuccessMsg(error);
                reset();
            } else {
                setSuccessMsg('');
                setErrorMsg(typeof error === 'string' ? error : error.message);
            }
        }
    };

    const goToVerifyStep = async () => {
        setState(true);
        setUploadDocumentState(false);
    }


    // const handleDocumentUpload = async () => {
    //     console.log(registerData);
    //     try {
    //         setState(false);
    //         setUploadDocumentState(false);
    //         const confirmCode = window.localStorage.getItem('success');
    //         if (documenttype !== '') { // Updated to use `verifycode`
    //             const formData = new FormData();
    //             formData.append('email', registerData.email);
    //             formData.append('password', registerData.password);
    //             formData.append('firstname', registerData.firstname);
    //             formData.append('lastname', registerData.lastname);
    //             formData.append('username', registerData.username);
    //             formData.append('phonenumber', registerData.phonenumber);
    //             formData.append('country', registerData.country);
    //             formData.append('month', registerData.month);
    //             formData.append('day', registerData.day);
    //             formData.append('year', registerData.year);
    //             formData.append('currency', 'USD');
    //             formData.append('status', 'pending');
    //             formData.append('roleId', 'user');
    //             formData.append('documents', frontFile); // Append the file
    //             formData.append('backdocuments', backFile);
    //             formData.append('documenttype', documenttype);

    //             // Call the register function (assuming it takes FormData)
    //             await register(formData);
    //             setState(false); // Reset the verification state
    //         } else {
    //             setErrorMsg('Document Type is missing');
    //         }
    //     } catch (error) {
    //         setErrorMsg(typeof error === 'string' ? error : error.message);
    //     }
    // };

    const renderHead = (
        <Stack spacing={2} sx={{ mb: 0, mt: 2, position: 'relative' }}>
            <Typography
                variant="h4"
                mt='1'
                sx={{
                    textAlign: 'center'
                }}
            >Create an Account</Typography>
            {/* <Typography variant="h4" textAlign="center" justifyContent="center">Create an Account</Typography> */}

            {/* <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="center">
                <Typography variant="body2"> Already have an account? </Typography>

                <Link href={paths.auth.jwt.login} component={RouterLink} variant="subtitle2">
                    Sign in
                </Link>
            </Stack> */}
        </Stack>
    );

    const renderTerms = (
        <Toolbar
            sx={{
                color: 'text.secondary',
                mt: 2.5,
                mb: 2.5,
                typography: 'caption',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Logo />
        </Toolbar>
        // <Typography
        //     component="div"
        //     sx={{
        //         color: 'text.secondary',
        //         mt: 2.5,
        //         typography: 'caption',
        //         textAlign: 'center'
        //     }}
        // >
        //     {'By signing up, I agree to '}
        //     <Link underline="always" color="text.primary">
        //         Terms of Service
        //     </Link>
        //     {' and '}
        //     <Link underline="always" color="text.primary">
        //         Privacy Policy
        //     </Link>
        //     .
        // </Typography>
    );

    const renderForm = (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2.5}>
                {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                {!!successMsg && <Alert severity="success">{successMsg}</Alert>}
                <Typography
                    component="div"
                    sx={{
                        color: 'text.secondary',
                        mt: 2.5,
                        typography: 'caption',
                        textAlign: 'center'
                    }}
                >
                    Step 1/3: Fill out your details
                </Typography>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} sx={{ width: '100%' }}>
                        <Typography
                            component="div"
                            sx={{
                                color: 'text.secondary',
                                // mt: 2.5,
                                typography: 'caption',
                                textAlign: 'left'
                            }}
                        >
                            Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <RHFTextField name="firstname" label=""
                            sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }} />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} sx={{ width: '100%' }}>
                        <Typography
                            component="div"
                            sx={{
                                color: 'text.secondary',
                                // mt: 2.5,
                                typography: 'caption',
                                textAlign: 'left'
                            }}
                        >
                            Surname <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <RHFTextField name="lastname" label=""
                            sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }}
                        />
                    </Stack>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} sx={{ width: '100%' }}>
                    <Typography
                        component="div"
                        sx={{
                            color: 'text.secondary',
                            // mt: 2.5,
                            typography: 'caption',
                            textAlign: 'left'
                        }}
                    >
                        Email <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <RHFTextField name="email" label=""
                        sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }} />

                </Stack>

                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} sx={{ width: '100%' }}>
                    <Typography
                        component="div"
                        sx={{
                            color: 'text.secondary',
                            // mt: 2.5,
                            typography: 'caption',
                            textAlign: 'left'
                        }}
                    >
                        Username <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <RHFTextField name="username" autoComplete="off" label=""
                        sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }} />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} sx={{ width: '100%' }}>
                    <Typography
                        component="div"
                        sx={{
                            color: 'text.secondary',
                            // mt: 2.5,
                            typography: 'caption',
                            textAlign: 'left'
                        }}
                    >
                        Password <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <RHFTextField
                        autoComplete="new-password"
                        name="password"
                        label=""
                        type={password.value ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={password.onToggle} edge="end">
                                        <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }} />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} sx={{ width: '100%' }}>
                    <Typography
                        component="div"
                        sx={{
                            color: 'text.secondary',
                            // mt: 2.5,
                            typography: 'caption',
                            textAlign: 'left'
                        }}
                    >
                        Confirm New Password <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <RHFTextField
                        name="confirmNewPassword"
                        type={confirmPassword.value ? 'text' : 'password'}
                        label=""
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={confirmPassword.onToggle} edge="end">
                                        <Iconify
                                            icon={confirmPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }}
                    />
                </Stack>


                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ width: '100%' }}>
                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} sx={{ width: '250px' }}>
                        <Typography
                            component="div"
                            sx={{
                                color: 'text.secondary',
                                width: '250px',
                                // mt: 2.5,
                                typography: 'caption',
                                textAlign: 'left'
                            }}
                        >
                            Country <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <RHFAutocomplete
                            name="country"
                            label=""
                            options={countries.map((country) => country.label)}
                            getOptionLabel={(option) => option}
                            renderOption={(props, option) => {
                                const { code, label, phone } = countries.filter((country) => country.label === option)[0];

                                if (!label) {
                                    return null;
                                }

                                return (
                                    <li {...props} key={label}>
                                        <Iconify
                                            key={label}
                                            icon={`circle-flags:${code.toLowerCase()}`}
                                            width={28}
                                            sx={{ mr: 1 }}
                                        />
                                        {label} ({code}) +{phone}
                                    </li>
                                );
                            }}
                            sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1', width: '250px' }}
                        />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} sx={{ width: '400px' }}>
                        <Typography
                            component="div"
                            sx={{
                                color: 'text.secondary',
                                // mt: 2.5,
                                typography: 'caption',
                                textAlign: 'left'
                            }}
                        >
                            Phone <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <RHFTextField name="phonenumber" label=""
                            sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }} />
                    </Stack>

                </Stack>

                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1}>
                    <Typography
                        component="div"
                        sx={{
                            color: 'text.secondary',
                            width: '100%',
                            // mt: 2.5,
                            typography: 'caption',
                            textAlign: 'left'
                        }}
                    >
                        Date of Birth <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={1} sx={{ width: '100%' }}>
                        <RHFAutocomplete
                            name="month"
                            label="Month"
                            options={monthsList.map((month) => month.code)}
                            getOptionLabel={(option) => option}
                            renderOption={(props, option) => {
                                const { code, label, phone } = monthsList.filter((month) => month.code === option)[0];

                                if (!label) {
                                    return null;
                                }

                                return (
                                    <li {...props} key={label}>
                                        {code}
                                    </li>
                                );
                            }}
                            sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1', width: '100%' }}
                        />

                        <RHFTextField name="day" label="DD"
                            sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }} />

                        <RHFTextField name="year" label="YYYY"
                            sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }} />
                    </Stack>
                </Stack>


                <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{ bgcolor: '#068DEE', color: '#1A2C38' }}
                >
                    Continue
                </LoadingButton>

            </Stack>
            {/* popupform-2 */}
            {/* <Stack gap={2} direction="column">
                {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                <Typography
                    component="div"
                    sx={{
                        color: 'text.secondary',
                        mt: 2.5,
                        typography: 'caption',
                        textAlign: 'center'
                    }}
                >
                    Step 2/3: Verify your Email
                </Typography>

                <RHFTextField name="email" label="Email address"
                    sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }} />
                <TextField
                    name="number"
                    label="Verify Code"
                    placeholder="12345"
                    onChange={(e) => {
                        setVerifyCode(e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                sx={{ cursor: 'pointer' }}
                                // sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setState(false);
                                }}
                            >
                                Back
                            </InputAdornment>
                        )
                    }}
                    sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }}
                />
                <LoadingButton fullWidth color="inherit" size="large" variant="contained" onClick={handleVerify}>
                    Go Back
                </LoadingButton>
                <LoadingButton fullWidth color="inherit" size="large" variant="contained" onClick={handleVerify}
                    sx={{ bgcolor: '#068DEE', color: '#1A2C38' }}>
                    Confirm
                </LoadingButton>
            </Stack> */}
            {/* popupform-3 */}
            {/* <Stack gap={2} direction="column">
                {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                <Typography
                    component="div"
                    sx={{
                        color: 'text.secondary',
                        mt: 2.5,
                        typography: 'caption',
                        textAlign: 'center'
                    }}
                >
                    Step 3/3: Upload your ID
                </Typography>

                <RHFTextField name="documenttype" autoComplete="off" label="Document Type" sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }} />

                <RHFUploadDocument
                    name="documents"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    labelText="Upload Front"
                />
                <RHFUploadDocument
                    name="documents"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    labelText="Upload Back"
                />
                <Typography
                    component="div"
                    sx={{
                        color: 'text.secondary',
                        mt: 2.5,
                        typography: 'caption',
                        textAlign: 'center'
                    }}
                >
                    Once your ID is verified you will receive a confirmation email and you can start enjoying our website, the process may take a few minutes.
                </Typography>
                <LoadingButton fullWidth color="inherit" size="large" variant="contained" onClick={handleVerify}>
                    Go Back
                </LoadingButton>
                <LoadingButton fullWidth color="inherit" size="large" variant="contained" onClick={handleVerify}
                    sx={{ bgcolor: '#068DEE', color: '#1A2C38' }}>
                    Countinue
                </LoadingButton>
            </Stack> */}
        </FormProvider>
    );
    const renderUploadDocumentForm = (

        <Stack gap={2} direction="column">
            {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            <Typography
                component="div"
                sx={{
                    color: 'text.secondary',
                    mt: 2.5,
                    typography: 'caption',
                    textAlign: 'center'
                }}
            >
                Step 3/3: Upload your ID
            </Typography>
            <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} sx={{ width: '100%' }}>
                <Typography
                    component="div"
                    sx={{
                        color: 'text.secondary',
                        width: '100%',
                        typography: 'caption',
                        textAlign: 'left'
                    }}
                >
                    Document Type
                </Typography>
                <Select
                    name="documenttype"
                    label=""
                    placeholder=""
                    value={documenttype}
                    onChange={(e) => {
                        setDocumentType(e.target.value);
                    }}
                    sx={{ bgcolor: '#13212D', color: '#B3BAD1' }}
                >
                    <MenuItem value="passport">Passport</MenuItem>
                    <MenuItem value="id_card">ID Card</MenuItem>
                    <MenuItem value="driving_license">Driving License</MenuItem>
                </Select>
            </Stack>
            {/* <RHFTextField name="documenttype" autoComplete="off" label="Document Type" sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }} /> */}

            <RHFUploadDocument
                name="documents"
                maxSize={3145728}
                onDrop={handleDrop}
                labelText="Upload Front"
            />

            <RHFUploadDocument
                name="backdocuments"
                maxSize={3145728}
                onDrop={handleBackDrop}
                labelText="Upload Back"
            />

            <Typography
                component="div"
                sx={{
                    color: 'text.secondary',
                    mt: 2.5,
                    typography: 'caption',
                    textAlign: 'center'
                }}
            >
                Once your ID is verified you will receive a confirmation email and you can start enjoying our website, the process may take a few minutes.
            </Typography>
            <LoadingButton fullWidth color="inherit" size="large" variant="contained" onClick={goToVerifyStep}>
                Go Back
            </LoadingButton>
            <LoadingButton fullWidth color="inherit" size="large" variant="contained" onClick={handleDocumentUpload}
                sx={{ bgcolor: '#068DEE', color: '#1A2C38' }}>
                Countinue
            </LoadingButton>
        </Stack>


    );
    const renderVerifyForm = (
        <>
            {uploadDocumentState ? (
                renderUploadDocumentForm
            ) : (
                <Stack gap={2} direction="column">
                    {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                    <Typography
                        component="div"
                        sx={{
                            color: 'text.secondary',
                            mt: 2.5,
                            typography: 'caption',
                            textAlign: 'center'
                        }}
                    >
                        Step 2/3: Verify your Email
                    </Typography>

                    {/* <RHFTextField name="email" label="Email address"
                        sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }} /> */}
                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} sx={{ width: '100%' }}>
                        <Typography
                            component="div"
                            sx={{
                                color: 'text.secondary',
                                // mt: 2.5,
                                typography: 'caption',
                                textAlign: 'left'
                            }}
                        >
                            Email
                        </Typography>
                        <TextField
                            name="userEmail"
                            label=""
                            placeholder=""
                            defaultValue={verifyemail}
                            onChange={(e) => {
                                setVerifyEmail(e.target.value);
                            }}
                            sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }}
                        />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} sx={{ width: '100%' }}>
                        <Typography
                            component="div"
                            sx={{
                                color: 'text.secondary',
                                // mt: 2.5,
                                typography: 'caption',
                                textAlign: 'left'
                            }}
                        >
                            Verification Code
                        </Typography>
                        <TextField
                            name="number"
                            label=""
                            placeholder="12345"
                            onChange={(e) => {
                                setVerifyCode(e.target.value);
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        sx={{ cursor: 'pointer' }}
                                        // sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setState(false);
                                        }}
                                    >
                                        Back
                                    </InputAdornment>
                                )
                            }}
                            sx={{ bgcolor: '#13212D', borderRadius: '8px', borderColor: '#5A7084', color: '#B3BAD1' }}
                        />
                    </Stack>
                    <LoadingButton fullWidth color="inherit" size="large" variant="contained" onClick={() => setState(false)}>
                        Go Back
                    </LoadingButton>
                    <LoadingButton fullWidth color="inherit" size="large" variant="contained" onClick={handleVerify}
                        sx={{ bgcolor: '#068DEE', color: '#1A2C38' }}>
                        Confirm
                    </LoadingButton>
                </Stack>
            )}
        </>
        // <Stack gap={2} direction="column">
        //     {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        //     <TextField
        //         name="number"
        //         label="Verify Code"
        //         placeholder="12345"
        //         onChange={(e) => {
        //             setVerifyCode(e.target.value);
        //         }}
        //         InputProps={{
        //             startAdornment: (
        //                 <InputAdornment
        //                     position="start"
        //                     sx={{ cursor: 'pointer' }}
        //                     onClick={() => {
        //                         setState(false);
        //                     }}
        //                 >
        //                     Back
        //                 </InputAdornment>
        //             )
        //         }}
        //     />
        //     <LoadingButton fullWidth color="inherit" size="large" variant="contained" onClick={handleVerify}>
        //         Confirm
        //     </LoadingButton>
        // </Stack>

    );



    return (
        <>
            {renderHead}

            {state ? renderVerifyForm : renderForm}

            {renderTerms}
        </>
    );
}
